(function () {
  'use strict';

  class Polls {
    constructor(PollsRest, $cookies, $rootScope) {
      this.PollsRest = PollsRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    pollsGet(polls = {}, callback = angular.noop) {
      return this.PollsRest.polls().get(polls,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    pollsCreate(polls = {}, callback = angular.noop) {
      return this.PollsRest.polls().save({poll: polls},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    pollsResponse(polls = {}, callback = angular.noop) {
      return this.PollsRest.response().save({poll_question_response: polls},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    pollsRemove(poll = {}, callback = angular.noop) {
      return this.PollsRest.polls().remove({poll: poll},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    pollsUpdate(poll = {}, callback = angular.noop) {
      return this.PollsRest.polls().update({poll: poll},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Polls
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Polls', Polls);
}());
